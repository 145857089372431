import { useState } from 'react';
import styled from 'styled-components';
import beerImage from '../assets/images/beer.svg';
import crossImage from '../assets/images/cross.svg';
import Overlay from './Overlay';
import SocialLinks from './SocialLinks';

const StyledSidebar = styled.div`
  transition: background-color 0.3s;
  background-color: ${(props) => (props.isOverlayOpen ? '#8965DD' : '#000000')};
  position: ${(props) => (props.isOverlayOpen ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  width: 48px;
  height: 100vh;
  z-index: 100;

  @media only screen and (max-width: 930px) {
    width: 100%;
    height: 96px;
  }
`;

const Hamburger = styled.div`
  width: 48px;
  height: 48px;
  background: url(${(props) => (props.isOverlayOpen ? crossImage : beerImage)});
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;

  @media only screen and (max-width: 930px) {
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
  }
`;

function Sidebar() {
  const [isOverlayOpen, setisOverlayOpen] = useState(false);

  return (
    <>
      <StyledSidebar isOverlayOpen={isOverlayOpen}>
        <Hamburger
          onClick={() => setisOverlayOpen((prevState) => !prevState)}
          isOverlayOpen={isOverlayOpen}
        />
        <SocialLinks />
      </StyledSidebar>
      <Overlay isOpen={isOverlayOpen} />
    </>
  );
}

export default Sidebar;

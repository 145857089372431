import styled from 'styled-components';
import ReactCountdown, { zeroPad } from 'react-countdown';

const CountdownText = styled.span`
  color: #000000;
  font-size: 104px;
  display: flex;
  margin: 0 -15px;
  font-weight: 300;
  font-variant: tabular-nums;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 12px;
  }

  span {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 1200px) {
    font-size: 75px;
  }

  @media only screen and (max-width: 930px) {
    font-size: 48px;
    margin: 0 -6px;

    div {
      margin: 0 6px;
    }

    span {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 374px) {
    font-size: 37px;
  }
`;

const RefreshButton = styled.a`
  display: inline-block;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor || '#ffb612'};
  color: #000000;
  font-size: 16px;
  border-radius: 22px;
  padding: 13px 20px;
  text-decoration: none;
`;

function refreshPage() {
  window.location.reload();
}

// Renderer callback with condition
const renderer = (
  { days, hours, minutes, seconds, completed },
  overtime,
  refreshButtonColor
) => {
  if (completed && !overtime) {
    return (
      <RefreshButton onClick={refreshPage} backgroundColor={refreshButtonColor}>
        Update
      </RefreshButton>
    );
  }
  // Render a countdown
  return (
    <CountdownText>
      <div>
        {zeroPad(days)}
        <span>days</span>
      </div>
      <div>
        {zeroPad(hours)} <span>hours</span>
      </div>
      <div>
        {zeroPad(minutes)} <span>mins</span>
      </div>
      <div>
        {zeroPad(seconds)} <span>secs</span>
      </div>
    </CountdownText>
  );
};

function Countdown({ date, overtime, refreshButtonColor }) {
  return (
    <ReactCountdown
      date={date}
      overtime={overtime}
      renderer={(props) => renderer(props, overtime, refreshButtonColor)}
    />
  );
}

export default Countdown;

import styled from 'styled-components';
import Tile from '../components/Tile';
import { JUNE_21, MAY_17 } from '../constants/dates';

const StyledPage4 = styled.div`
  height: 100vh;
  padding-left: 48px;

  /* Grid Fallback */
  display: flex;
  flex-wrap: wrap;

  /* Supports Grid */
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'one two two'
    'three four five';

  @media only screen and (max-width: 930px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-left: 0;
    padding-top: 96px;
  }
`;

function Page4() {
  return (
    <StyledPage4>
      <Tile
        title={`You can now have \nbeer in a beer garden, \npark, or garden, \nwith five friends!`}
        backgroundColor="#93D1AC"
        gridArea="one"
        stageComplete
      />
      <Tile
        title={`Beer in a pub, \nwith five friends`}
        backgroundColor="#FFB612"
        gridArea="two"
        date={new Date(MAY_17)}
        hasCountdown
        countdownrefreshButtonColor="#93D1AC"
      />
      <Tile
        title={`Beer everywhere, \nwith everyone`}
        backgroundColor="#8965DD"
        gridArea="three"
        date={new Date(JUNE_21)}
      />
      <Tile gridArea="four" allowGrow />
      <Tile backgroundColor="#FA9CBE" gridArea="five" allowGrow />
    </StyledPage4>
  );
}

export default Page4;

import styled from 'styled-components';
import Tile from '../components/Tile';
import { APRIL_12, MAY_17, JUNE_21 } from '../constants/dates';

const StyledPage3 = styled.div`
  height: 100vh;
  padding-left: 48px;

  /* Grid Fallback */
  display: flex;
  flex-wrap: wrap;

  /* Supports Grid */
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'one two two'
    'three four five';

  @media only screen and (max-width: 930px) {
    display: block;
    padding-left: 0;
    padding-top: 96px;
  }
`;

function Page3() {
  return (
    <StyledPage3>
      <Tile
        title={`You can now have \nbeer in the park or garden, \nwith five friends!`}
        backgroundColor="#FA9CBE"
        gridArea="one"
        stageComplete
      />
      <Tile
        title={`Beer in a beer garden, \nwith five friends`}
        backgroundColor="#93D1AC"
        gridArea="two"
        date={new Date(APRIL_12)}
        hasCountdown
      />
      <Tile
        title={`Beer in a pub, \nwith five friends`}
        backgroundColor="#FFB612"
        gridArea="three"
        date={new Date(MAY_17)}
      />
      <Tile
        title={`Beer everywhere, \nwith everyone`}
        backgroundColor="#8965DD"
        gridArea="four"
        date={new Date(JUNE_21)}
      />
      <Tile gridArea="five" />
    </StyledPage3>
  );
}

export default Page3;

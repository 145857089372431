import styled from 'styled-components';
import Tile from '../components/Tile';
import { JULY_19 } from '../constants/dates';

const StyledPage6 = styled.div`
  height: 100vh;
  padding-left: 48px;

  /* Grid Fallback */
  display: flex;
  flex-wrap: wrap;

  /* Supports Grid */
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'one one two'
    'three four five';

  @media only screen and (max-width: 930px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-left: 0;
    padding-top: 96px;
  }
`;

function Page6() {
  return (
    <StyledPage6>
      <Tile
        title={`Beer everywhere, \nwith everyone`}
        backgroundColor="#8965DD"
        gridArea="one"
        date={new Date(JULY_19)}
        hasCountdown
        countdownOvertime
      />
      <Tile gridArea="two" allowGrow />
      <Tile backgroundColor="#FA9CBE" gridArea="three" allowGrow />
      <Tile backgroundColor="#93D1AC" gridArea="four" allowGrow />
      <Tile backgroundColor="#FFB612" gridArea="five" allowGrow />
    </StyledPage6>
  );
}

export default Page6;

import Sidebar from './components/Sidebar';
import Page1 from './pages/page1';
import Page2 from './pages/page2';
import Page3 from './pages/page3';
import Page4 from './pages/page4';
import Page5 from './pages/page5';
import Page6 from './pages/page6';
import {
  MARCH_08,
  MARCH_29,
  APRIL_12,
  MAY_17,
  JULY_19,
} from './constants/dates';

function App() {
  return (
    <>
      <Sidebar />
      {Date.now() < new Date(MARCH_08) && <Page1 />}
      {Date.now() > new Date(MARCH_08) && Date.now() < new Date(MARCH_29) && (
        <Page2 />
      )}
      {Date.now() > new Date(MARCH_29) && Date.now() < new Date(APRIL_12) && (
        <Page3 />
      )}
      {Date.now() > new Date(APRIL_12) && Date.now() < new Date(MAY_17) && (
        <Page4 />
      )}
      {Date.now() > new Date(MAY_17) && Date.now() < new Date(JULY_19) && (
        <Page5 />
      )}
      {Date.now() > new Date(JULY_19) && <Page6 />}
    </>
  );
}

export default App;

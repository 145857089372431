import { useEffect } from 'react';
import styled from 'styled-components';
import { MARCH_29, APRIL_12, MAY_17, JULY_19 } from '../constants/dates';

const StyledOverlay = styled.div`
  background-color: #000000;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  position: fixed;
  top: 0;
  left: 48px;
  padding: 34px 24px;
  width: 100vw;
  height: 100vh;
  font-size: 24px;
  color: #ffffff;
  z-index: 90;

  @media only screen and (max-width: 930px) {
    left: 0;
    padding-top: 127px;
    padding-left: 60px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 380px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 340px) {
    font-size: 14px;
  }
`;

const Title = styled.p`
  margin: 0;

  @media only screen and (max-width: 930px) {
    margin-left: -24px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.linkHoverColor || '#ffb612'};
  }
`;

const ListWrapper = styled.div`
  display: flex;

  @media only screen and (max-width: 930px) {
    display: block;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 80px 0 0;
  padding: 0;

  :first-child {
    margin-right: 140px;

    @media only screen and (max-width: 930px) {
      margin-right: 0;
    }
  }
`;

const ListItem = styled.li`
  margin-bottom: 20px;

  span {
    position: relative;
    display: inline-block;
    min-width: 140px;

    @media only screen and (max-width: 480px) {
      min-width: 120px;
    }

    @media only screen and (max-width: 380px) {
      min-width: 100px;
    }

    @media only screen and (max-width: 340px) {
      min-width: 80px;
    }
  }
`;

const Indicator = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -24px;
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color || '#fd5b2a'};
  border-radius: 100%;
`;

const DisabledStage = styled.div`
  color: rgba(255, 255, 255, 0.5);
  text-decoration: line-through;

  span {
    text-decoration: line-through;
  }
`;

function Overlay({ isOpen }) {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overlay-open');
    } else {
      document.body.classList.remove('overlay-open');
    }
  }, [isOpen]);

  return (
    <StyledOverlay isOpen={isOpen}>
      <Title>One way road to beer . com</Title>
      <ListWrapper>
        <List>
          <ListItem>
            <span>Design</span>
            <Link
              href="https://linktr.ee/ruthersish/"
              target="_blank"
              rel="noreferrer"
              linkHoverColor="#FD5B2A"
            >
              Graeme Rutherford
            </Link>
          </ListItem>
          <ListItem>
            <span>Code</span>
            <Link
              href="https://linktr.ee/apamphilon/"
              target="_blank"
              rel="noreferrer"
            >
              Alex Pamphilon
            </Link>
          </ListItem>
          <ListItem>
            <span>Colours</span>
            <Link
              href="http://www.iamkatieb.com/"
              target="_blank"
              rel="noreferrer"
              linkHoverColor="#93D1AC"
            >
              Katie Baxendale
            </Link>
          </ListItem>
          <ListItem>
            <span>Data</span>
            <Link
              href="https://www.gov.uk/government/publications/covid-19-response-spring-2021/covid-19-response-spring-2021-summary/"
              target="_blank"
              rel="noreferrer"
              linkHoverColor="#FA9CBE"
            >
              GOV.UK
            </Link>
          </ListItem>
        </List>
        <List>
          <ListItem>
            {Date.now() > new Date(MARCH_29) ? (
              <DisabledStage>
                <span>Step 1a</span>March 8th
              </DisabledStage>
            ) : (
              <>
                <span>
                  {Date.now() < new Date(MARCH_29) && (
                    <Indicator color="#FD5B2A" />
                  )}
                  Step 1a
                </span>
                March 8th
              </>
            )}
          </ListItem>
          <ListItem>
            {Date.now() > new Date(APRIL_12) ? (
              <DisabledStage>
                <span>Step 1b</span>
                March 29th
              </DisabledStage>
            ) : (
              <>
                <span>
                  {Date.now() > new Date(MARCH_29) &&
                    Date.now() < new Date(APRIL_12) && (
                      <Indicator color="#FA9CBE" />
                    )}
                  Step 1b
                </span>
                March 29th
              </>
            )}
          </ListItem>
          <ListItem>
            {Date.now() > new Date(MAY_17) ? (
              <DisabledStage>
                <span>Step 2</span>April 12th
              </DisabledStage>
            ) : (
              <>
                <span>
                  {Date.now() > new Date(APRIL_12) &&
                    Date.now() < new Date(MAY_17) && (
                      <Indicator color="#93D1AC" />
                    )}
                  Step 2
                </span>
                April 12th
              </>
            )}
          </ListItem>
          <ListItem>
            {Date.now() > new Date(JULY_19) ? (
              <DisabledStage>
                <span>Step 3</span>May 17th
              </DisabledStage>
            ) : (
              <>
                <span>
                  {Date.now() > new Date(MAY_17) &&
                    Date.now() < new Date(JULY_19) && (
                      <Indicator color="#FFB612" />
                    )}
                  Step 3
                </span>
                May 17th
              </>
            )}
          </ListItem>
          <ListItem>
            <span>
              {Date.now() > new Date(JULY_19) && <Indicator color="#8965DD" />}
              Step 4
            </span>
            July 19th
          </ListItem>
        </List>
      </ListWrapper>
    </StyledOverlay>
  );
}

export default Overlay;

import styled from 'styled-components';
import Countdown from './Countdown';
import StaticCountdown from './StaticCountdown';

const Wrapper = styled.div`
  background: ${(props) => props.backgroundColor || '#fd5b2a'};
  grid-area: ${(props) => props.gridArea || 1};
  padding: 34px 24px;

  @media only screen and (max-width: 930px) {
    flex: ${(props) => props.allowGrow && 1};
    flex-grow: ${(props) => !props.allowGrow && 0};
  }
`;

const Heading = styled.h2`
  color: #000000;
  font-size: 24px;
  white-space: ${(props) => (props.hasCountdown ? 'normal' : 'pre-wrap')};
  font-weight: 400;
  margin: ${(props) => (props.stageComplete ? 0 : '0 0 10px')};
  line-height: 1.33;

  @media only screen and (max-width: 930px) {
    margin: ${(props) => (props.stageComplete ? 0 : '0 0 20px')};
    font-size: 20px;
    white-space: normal;
  }

  @media only screen and (max-width: 430px) {
    font-size: 20px;
    white-space: ${(props) => !props.stageComplete && 'pre-wrap'};
  }
`;

function Tile({
  backgroundColor,
  title,
  gridArea,
  hasCountdown,
  date,
  stageComplete,
  countdownOvertime,
  countdownrefreshButtonColor,
  allowGrow,
}) {
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      gridArea={gridArea}
      allowGrow={allowGrow}
    >
      <Heading stageComplete={stageComplete} hasCountdown={hasCountdown}>
        {title}
      </Heading>
      {!stageComplete && hasCountdown && date && (
        <Countdown
          date={date}
          overtime={countdownOvertime}
          refreshButtonColor={countdownrefreshButtonColor}
        />
      )}
      {!stageComplete && !hasCountdown && date && (
        <StaticCountdown date={date} />
      )}
    </Wrapper>
  );
}

export default Tile;

import styled from 'styled-components';
import { differenceInDays } from 'date-fns';

const StyledStaticCountdown = styled.span`
  color: #000000;
  font-size: 64px;
  font-weight: 300;

  span {
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 930px) {
    font-size: 56px;
  }
`;

function StaticCountdown({ date }) {
  return (
    <StyledStaticCountdown>
      {differenceInDays(date, Date.now())}
      <span> days</span>
    </StyledStaticCountdown>
  );
}

export default StaticCountdown;

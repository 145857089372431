import styled from 'styled-components';
import Tile from '../components/Tile';
import {
  MARCH_08,
  MARCH_29,
  APRIL_12,
  MAY_17,
  JUNE_21,
} from '../constants/dates';

const StyledPage1 = styled.div`
  height: 100vh;
  padding-left: 48px;

  /* Grid Fallback */
  display: flex;
  flex-wrap: wrap;

  /* Supports Grid */
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'one one two'
    'three four five';

  @media only screen and (max-width: 930px) {
    display: block;
    padding-left: 0;
    padding-top: 96px;
  }
`;

function Page1() {
  return (
    <StyledPage1>
      <Tile
        title="Beer in the park, with one friend"
        gridArea="one"
        hasCountdown
        date={new Date(MARCH_08)}
      />
      <Tile
        title={`Beer in the park, \nwith five friends`}
        backgroundColor="#FA9CBE"
        gridArea="two"
        date={new Date(MARCH_29)}
      />
      <Tile
        title={`Beer in a beer garden, \nwith five friends`}
        backgroundColor="#93D1AC"
        gridArea="three"
        date={new Date(APRIL_12)}
      />
      <Tile
        title={`Beer in a pub, \nwith five friends`}
        backgroundColor="#FFB612"
        gridArea="four"
        date={new Date(MAY_17)}
      />
      <Tile
        title={`Beer everywhere, \nwith everyone`}
        backgroundColor="#8965DD"
        gridArea="five"
        date={new Date(JUNE_21)}
      />
    </StyledPage1>
  );
}

export default Page1;

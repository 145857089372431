import styled from 'styled-components';
import instagramIcon from '../assets/images/icon-instagram.svg';
import twitterIcon from '../assets/images/icon-twitter.svg';

const Wrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 930px) {
    flex-direction: row;
    align-items: center;
    left: auto;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
  }
`;

const Link = styled.a`
  margin: 0 0 20px;
  line-height: 0;

  &:last-child {
    margin: 0;
  }

  @media only screen and (max-width: 930px) {
    margin: 0 20px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

function SocialLinks() {
  return (
    <Wrapper>
      <Link
        href="https://www.instagram.com/onewayroadtobeer/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={instagramIcon} alt="Instagram icon" width="24" height="24" />
      </Link>
      <Link
        href="https://twitter.com/onewayroad2beer/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={twitterIcon} alt="Twitter icon" width="24" height="24" />
      </Link>
    </Wrapper>
  );
}

export default SocialLinks;
